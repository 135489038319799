import React from 'react';
import ComingSoon from './components/ComingSoon';

export default class App extends React.Component {

    render() {
        return (
            <ComingSoon />
        );
    }
}