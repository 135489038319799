import React from 'react';
import './ComingSoon.css';
import logomark from '../images/logomark.png';

export default class ComingSoon extends React.Component {
    render() {
        return (
            <div className="container h-100">
                <div className="row align-items-center pt-2">
                    <img src={logomark} className="img-fluid mx-auto" id="coming-soon-logomark" />

                </div>
                <div className="row">
                    <p className="mx-auto" id="coming-soon-text">
                        Coming soon...
                    </p>
                </div>
            </div>
        );
    }
}